import React from 'react';
import { setClassNames } from 'ddc-classnames-js';
import { useSelector } from 'react-redux';
import { usePrefs } from 'wsm-common-data';

const Image = () => {
	const { url } = useSelector((state) => state.vehicleData);
	const {
		imageWidth,
		imageHeight,
		enableImagePreload,
		imageHorizontalAlignment,
		imageVerticalAlignment,
		imageContainerClasses
	} = usePrefs();

	const isImagePreloaded = enableImagePreload === 'true';
	const imgSrc = isImagePreloaded
		? { src: url }
		: { src: '/images/blank.gif', 'data-src': url };

	if (!url) {
		return null;
	}

	return (
		<div
			className={setClassNames([
				'd-flex',
				'flex-grow-1',
				imageHorizontalAlignment,
				imageVerticalAlignment,
				...imageContainerClasses.split(' ')
			])}
			data-testid="image-container"
		>
			<img
				className={setClassNames([
					'dynamic-resize',
					'img-responsive',
					!isImagePreloaded && 'lazy-image'
				])}
				height={imageHeight || null}
				width={imageWidth || null}
				alt=""
				{...imgSrc}
			/>
		</div>
	);
};

export default Image;
