import { SET_WIDGET_DATA } from '../actions/types';

export default function widgetData(
	state = {
		error: false,
		preowned: false
	},
	action
) {
	if (!action.error && action.payload !== undefined) {
		switch (action.type) {
			case SET_WIDGET_DATA:
				return action.payload;

			// no default
		}
	}

	return state;
}
