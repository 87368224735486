import React from 'react';
import { useSelector } from 'react-redux';
import { useLabels } from 'wsm-common-data';
import Container from '../components/Container';

const Widget = () => {
	const labels = useLabels();
	const { error, preowned } = useSelector((state) => state.widgetData);

	// If there is an error, then we must be in Composer
	// because statusCode 204 is returned in store.js
	if (error) {
		return (
			<div className="d-flex align-items-center justify-content-center">
				<i
					className="ddc-icon ddc-icon-single-vehicle icon-size-9"
					role="presentation"
					aria-hidden="true"
				/>
				<div className="composer-editor-only">
					<p>
						{labels.getHTML(
							preowned
								? 'DISABLED_ON_WEBSITE_PREOWNED_NOT_SUPPORTED'
								: 'DISABLED_ON_WEBSITE_VEHICLE_IMAGE'
						)}
					</p>
				</div>
			</div>
		);
	} else {
		return <Container />;
	}
};

export default Widget;
