import React from 'react';
import { useSelector } from 'react-redux';
import { useLabels, usePrefs } from 'wsm-common-data';
import { setClassNames } from 'ddc-classnames-js';
import { getVehicleName } from '../utils/getVehicleName';
import { VEHICLE_NAME_CONFIGURATION } from '../constants/global';

const VehicleName = () => {
	const labels = useLabels();
	const { vehicleNameClasses } = usePrefs();
	const { vehicleData } = useSelector((state) => state);

	const name = getVehicleName(VEHICLE_NAME_CONFIGURATION, vehicleData);
	const [firstLine, ...rest] = name.split(' ');
	const nameArray = [firstLine, rest.join(' ')];

	return (
		<h3
			className={setClassNames([
				'spacing-reset',
				...vehicleNameClasses.split(' ')
			])}
		>
			{nameArray.map((n, i) => {
				return (
					<React.Fragment key={n}>
						<span className="d-block" data-testid="block">
							{i === 0 ? `${labels.get('NEW')} ${n}` : n}
						</span>
						{i !== nameArray.length - 1 ? ' ' : ''}
					</React.Fragment>
				);
			})}
		</h3>
	);
};

export default VehicleName;
