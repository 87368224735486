import { SET_VEHICLE_DATA } from '../actions/types';
import { VEHICLE_DATA_OBJECT } from '../constants/global';

export default function vehicleData(state = VEHICLE_DATA_OBJECT, action) {
	if (!action.error && action.payload !== undefined) {
		switch (action.type) {
			case SET_VEHICLE_DATA:
				return action.payload;

			// no default
		}
	}

	return state;
}
