import { RIGHT } from '../constants/global';

export const getPositionClasses = (showText, showLink, infoPosition) => {
	const containerClasses = ['d-flex', 'w-100', 'h-100'];
	const infoMarginClasses = [];
	const infoAlignmentClasses = [];
	const innerInfoContainerClasses = [];
	const textMarginClasses = [];

	if (showText || showLink) {
		// Below the -md- breakpoint, the vehicle info should appear below the image
		containerClasses.push(...['flex-column-reverse']);
		infoMarginClasses.push(...['mt-3', 'mx-7']);
		if (showText && showLink) {
			// The text and the link should be side by side with space between them
			innerInfoContainerClasses.push(
				...['d-flex', 'justify-content-between', 'w-100']
			);
			textMarginClasses.push('mr-4');
		}

		// Above the -md- breakpoint, the vehicle info should appear to the right or the left of the image
		containerClasses.push(
			infoPosition === RIGHT ? 'flex-md-row-reverse' : 'flex-md-row'
		);
		infoMarginClasses.push(
			...[
				'mt-md-0',
				'mx-md-0',
				infoPosition === RIGHT ? 'ml-md-5' : 'mr-md-5'
			]
		);
		// Info should be vertically centered
		infoAlignmentClasses.push(...['d-md-flex', 'align-items-md-center']);
		if (showText && showLink) {
			// The link should be below the text
			innerInfoContainerClasses.push(
				...['flex-md-column', 'justify-content-md-start']
			);
			textMarginClasses.push(...['mr-md-0', 'mb-md-4']);
		}
	}

	return {
		containerClasses,
		infoMarginClasses,
		infoAlignmentClasses,
		innerInfoContainerClasses,
		textMarginClasses
	};
};
