export const BOTTOM = 'bottom';
export const RIGHT = 'right';
export const LEFT = 'left';
export const VEHICLE_DATA_OBJECT = {
	year: '',
	make: '',
	model: '',
	bodystyle: '',
	price: '',
	url: ''
};
export const VEHICLE_NAME_CONFIGURATION = 'year make model bodystyle';
