import React from 'react';
import { useSelector } from 'react-redux';
import { usePrefs } from 'wsm-common-data';
import { setClassNames } from 'ddc-classnames-js';
import VehicleName from './VehicleName';
import VehiclePrice from './VehiclePrice';
import InventoryLink from './InventoryLink';
import Image from './Image';
import { VEHICLE_NAME_CONFIGURATION } from '../constants/global';
import { getPositionClasses } from '../utils/getPositionClasses';

const Container = () => {
	const { vehicleData } = useSelector((state) => state);
	const { showVehicleInfo, vehicleInfoPosition, linkLabel } = usePrefs();

	// The associated data for name and price will not be returned by `vehicleData` when the
	// `imagePathOverride` preference is set (see store.js). This means that the `showText` and `showLink` variables
	// will return `false`, and only the Image component will be rendered.
	const showInfo = showVehicleInfo === 'true';
	const hasName = VEHICLE_NAME_CONFIGURATION.split(' ').every(
		(item) => !!vehicleData[item]
	);
	const showName = showInfo && hasName;
	const showPrice = showInfo && vehicleData.price !== '';
	const showText = showName || showPrice;
	const showLink = showInfo && linkLabel !== '' && hasName;

	const {
		containerClasses,
		infoMarginClasses,
		infoAlignmentClasses,
		innerInfoContainerClasses,
		textMarginClasses
	} = getPositionClasses(showText, showLink, vehicleInfoPosition);

	return (
		<div
			className={setClassNames(containerClasses)}
			data-testid="container"
		>
			{(showText || showLink) && (
				<div
					className={setClassNames([
						...infoMarginClasses,
						...infoAlignmentClasses
					])}
					data-testid="info-container"
				>
					<div
						className={setClassNames(innerInfoContainerClasses)}
						data-testid="inner-info-container"
					>
						{showText && (
							<div
								className={setClassNames(textMarginClasses)}
								data-testid="text-container"
							>
								{showName && <VehicleName />}
								{showPrice && <VehiclePrice />}
							</div>
						)}
						{showLink && (
							<div>
								<InventoryLink />
							</div>
						)}
					</div>
				</div>
			)}
			<Image />
		</div>
	);
};

export default Container;
