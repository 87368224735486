export const getVehicleName = (
	vehicleNameConfiguration = '',
	vehicleData = {}
) => {
	return vehicleNameConfiguration
		.split(' ')
		.map((item) => vehicleData[item])
		.filter(Boolean)
		.join(' ');
};
