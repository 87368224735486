import React from 'react';
import { useSelector } from 'react-redux';
import { useSitemap, useLabels, usePrefs } from 'wsm-common-data';
import { getVehicleParams } from '../utils/getVehicleParams';
import { getVehicleName } from '../utils/getVehicleName';
import { VEHICLE_NAME_CONFIGURATION } from '../constants/global';

const InventoryLink = () => {
	const sitemap = useSitemap();
	const labels = useLabels();
	const { linkStyle, linkLabel } = usePrefs();
	const { vehicleData } = useSelector((state) => state);

	const vehicleParams = getVehicleParams(
		VEHICLE_NAME_CONFIGURATION,
		vehicleData
	);
	const vehicleName = getVehicleName(VEHICLE_NAME_CONFIGURATION, vehicleData);
	const queryString = `?${vehicleParams}`;

	return (
		<a
			className={`btn ${linkStyle}`}
			href={`${sitemap.get(
				'INVENTORY_LISTING_DEFAULT_AUTO_NEW'
			)}${queryString}`}
		>
			{labels.get(linkLabel)}
			<span className="sr-only">{`: ${labels
				.get('VEHICLE_NAME_PLACEHOLDER_INVENTORY')
				.replace('{{vehicle name}}', vehicleName)
				.trim()}`}</span>
		</a>
	);
};

export default InventoryLink;
