import React from 'react';
import { useSelector } from 'react-redux';
import { useLabels } from 'wsm-common-data';

const VehiclePrice = () => {
	const { price } = useSelector((state) => state.vehicleData);
	const labels = useLabels();

	return (
		<p className="spacing-reset">
			{`${labels.get('STARTING_AT')} ${price}`.trim()}
		</p>
	);
};

export default VehiclePrice;
